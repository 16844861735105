<template>
  <div class="foot-view">
      <div class="foot-view-top">
        <div class="left">
          <img :src="companyInfo.wx_image" class="qr_code">
          <p class="qr_code-msg">{{$t('footer.footText_5')}}</p>
        </div>
        <div class="right">
          <div class="right-box">
            <div class="right-box-left">
              <span class="span-1">{{$t('footer.footText_4')}}</span>
              <span class="span-2">{{companyInfo.tel}}</span>
            </div>
            <a :href="'tel:'+ companyInfo.tel" class="a-phone">
              <img src="../../assets/forestage/img/m-phone.png" alt="">
              <span>立即拨打</span>
            </a>
          </div>
          <div class="right-text">
            <img src="../../assets/forestage/img/emil.png" alt="">
            <p>{{$t('footer.footText_2')}}：</p><p>{{companyInfo.email}}</p>
          </div>
          <div class="right-text">
            <img src="../../assets/forestage/img/addresm.png" alt="">
            <p>{{$t('footer.footText_3')}}：</p><p>{{lang == 'cn'?companyInfo.cn.address:companyInfo.en.address}}</p>
          </div>
        </div>
      </div>
      <div class="foot-view-b">
        <p class="main-only">版权公告©2022 重庆振合网网络科技有限公司。版权所有。</p>
        <p @click="openIcp(1)" style="margin-bottom: 10px;">
          <img src="@/assets/forestage/images/bah.png" class="m-police-icon">
          渝公网安备 50011202503905号
        </p>
        <p @click="openIcp(2)">
          <img src="@/assets/forestage/images/bah.png" class="m-police-icon">
          渝ICP备{{companyInfo.icp}}
        </p>
        <!-- 号 渝公网安备 {{companyInfo.put_on_record}} -->
      </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {lang:'cn',}
    },
    computed: {
      ...mapGetters({
        companyInfo: 'companyInfo'
      }),
    },
    async created() {
      let that = this;
      window.addEventListener("setItemEvent", function(e) {
        if (e.key === "lang") {
          that.lang = e.newValue;
        }
      })

      await this.$store.dispatch('user/getCompanyInfo')
    },
    methods:{
      // openIcp(){
      //   window.open('https://beian.miit.gov.cn/#/Integrated/index')
      // },
      openIcp(type){
        let url = ''
        if(type === 1){
          url = 'https://www.beian.gov.cn/portal/registerSystemInfo'
        }else{
          url = 'https://beian.miit.gov.cn/#/Integrated/recordQuery'
        }
        window.open(url)
      },
    },
  }
</script>

<style scoped lang="scss">
  .foot-view{
    width: 100%;
    min-height: 944.64px;
    background-color: #25354A;
    box-sizing: border-box;
    padding: 81.92px;
    color: #fff;
    .foot-view-b{
      font-size: 51.2px;
      text-align: center;
      color: #A6ADB6;
      .main-only{
        margin: 81.92px auto 43.52px auto;
        line-height: 1.5;
        width: 70%;
      }
      .m-police-icon{
        width: 51.2px;
        height: 51.2px;
      }
    }
    .foot-view-top{
      display: flex;
      border-bottom: 1px solid #56667B;
      padding-bottom: 94.72px;
      .left{
        text-align: center;
        margin-right: 64px;
        img{
          width: 358.44px;
          height: 358.44px;
          margin-bottom: 51.2px;
        }
        .qr_code-msg{
          font-size: 51.2px;
        }
      }
      .right{
        .right-text{
          display: flex;
          margin-top: 64px;
          img{
            width: 81.92px;
            height: 81.92px;
            margin-right: 15.36px;
          }
          p{
            font-size: 61.44px;
            margin-top: 10px;
          }
        }
        .right-box{
          display: flex;
          .a-phone{
            display: block;
            background: #1B74E5;
            border-radius:20.48px;
            width: 424.96px;
            height: 163.84px;
            margin-left: 51.2px;
            box-sizing: border-box;
            padding-top: 40.96px;
            padding-left: 40.96px;
            display: flex;
            img{
              width: 81.92px;
              height: 81.92px;
              margin-right: 15.36px;
            }
            span{
              display: inline-block;
              font-size: 61.44px;
              margin-top: 10px;
            }
          }
          .right-box-left{
            span{
              display: block;
            }
            .span-1{
              font-size: 51.2px;
              color: #A7AEB6 !important;
            }
            .span-2{
              margin-top: 20px;
              font-size: 76.88px;
              font-weight: 600;
            }
          }
        }

      }
    }
  }
</style>
